<template>
  <div class="">
    <v-row>
      <v-col md="12">
        <manifest-log-list />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    ManifestLogList: () =>
      import("@/components/manifest-log/NewManifestLog.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
